<template>
  <moe-page title="预售活动列表">
    <moe-inquire @search="presaleSearch">
      <el-form-item label="活动编号">
        <el-input :value="presaleParams.id" @input="(value) => presaleParams.id = value.replace(/^\.+|[^\d]/g, '')" placeholder="输入活动编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="活动名称">
        <el-input v-model.trim="presaleParams.name" placeholder="输入活动名称" maxlength="100" clearable />
      </el-form-item>

      <el-form-item label="活动状态">
        <moe-select v-model="presaleParams.state" type="presaleState" placeholder="选择活动状态"></moe-select>
      </el-form-item>
    </moe-inquire>
    <moe-table ref="presaleTable" url="/shop/presale/list" :params="presaleParams" :number-show="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/presale/add')">新增预售活动</el-button>
      </template>

      <el-table-column label="活动编号" prop="id" width="100" />
      <el-table-column label="活动名称" prop="name" :show-overflow-tooltip="true" />
      <el-table-column label="预售商品(数量)" prop="goodsNum" min-width="150" />

      <el-table-column label="预售时间" min-width="310">
        <div class="df fdc aic jcc" slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.depositStartTime) }} 至 {{ $moe_time.getTimeStamp(row.depositEndTime) }}</div>
          <div>预售持续：{{ $moe_time.countDown(row.depositEndTime, row.depositStartTime) }}</div>
        </div>
      </el-table-column>

      <el-table-column label="尾款支付时间" min-width="310">
        <div class="df fdc aic jcc" slot-scope="{ row }">
          <div>{{ $moe_time.getTimeStamp(row.balanceStartTime) }} 至 {{ $moe_time.getTimeStamp(row.balanceEndTime) }}</div>
          <div>持续{{ $moe_time.countDown(row.balanceEndTime, row.balanceStartTime) }}</div>
        </div>
      </el-table-column>

      <el-table-column label="活动状态" width="100">
        <div :class="$moe_format.getPresaleStateColor(row.state)" slot-scope="{ row }">
          {{ $moe_format.getPresaleState(row.state) }}
        </div>
      </el-table-column>

      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="350">
        <template slot-scope="{ row }">
          <el-button v-if="['PENDING', 'IN_PROGRESS'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/presale/add`, { id: row.id })">编辑活动</el-button>
          <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/presale/detail`, { id: row.id })">查看活动</el-button>
          <el-button v-if="['PENDING'].includes(row.state)" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManagePresaleList',
  data() {
    return {
      presaleParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        state: '',
      }
    }
  },
  methods: {
    presaleSearch(isSearch) {
      if (!isSearch) {
        this.presaleParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          state: '',
        }
      }

      this.$refs['presaleTable'].searchData();
    },
    /** 删除预售活动 */
    handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项“${name}”吗？`, () => {
        this.$moe_api.PRESALE_API.delPresaleActivity({ id }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['presaleTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      });
    }
  },
}
</script>

<style lang="scss">

</style>